.setting-option {
    display: flex;
    justify-content: left;
    font-size: 18px;
    white-space: pre-line;
    width: 100%;
}
.setting-option-header {
    display: inline-block;
    text-align: left;
    margin: 0px;
    padding: 0px 5px ;
}

.setting-option-input {
    font-family: inherit;
    font-size: 18px;
    width: 2em;
    text-align: right;
    border-radius: 2px;
    border: none;
    background-color: #404040;
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}