.progress-ability-container {
    display: flex;
    flex-direction: column;
}

.progress-ability {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.charge-text {
    position: absolute;
    left: 35px;
    top: 23px;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    font-size: 20px;
}

img {
    border: 1px solid black
}

img.colored {
    filter: none;
    -webkit-filter: grayscale(0%);
    -moz-filter:    grayscale(0%);
    -ms-filter:     grayscale(0%);
    -o-filter:      grayscale(0%);
}

img.desaturated {
    filter: none;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
}