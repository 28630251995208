.cast-bar-text-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
}

#cast-bar {
    width: 100%
}

.cast-bar {
    background-color: #303030
}

.cast-bar-text {
    font-size: 15px;
    padding: 5px;
}