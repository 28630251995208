.progress-bar-container {
    width: 400px;
    height: 25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid black;
    background-color: black;
}

.progress-bar {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-right: 1px solid black;
}

.progress-text {
    font-size: 20px;
    z-index: 1;
}

.resource-bar {
    background-color: rgba(102, 0, 204, 0.808);
}

