.panel {
    position: fixed;
    z-index: 1;
    background-color: #111;
    transition: 0.5s;
}

.panel-header {
    padding: 5px;
    font-size: 15px;
}

.panel-button {
    background-color: #404040;
    border-radius: 4px;
    border-width: 2px;
    border-color: rgb(118, 118, 118);
    border-style: outset;
    color: white;
    cursor: pointer;
}

.panel-button-secondary {
    background-color: black;
    border-radius: 4px;
    border-width: 2px;
    border-color: rgb(118, 118, 118);
    border-style: outset;
    color: white;
    cursor: pointer;
}

.panel-button:hover,.panel-button-secondary:hover {
    background-color: #202020;
}

.panel-exit-button {
    position: absolute;
    border: none;
    font-size: 25px;
    top: 0px;
    right: 0px;
}

.panel-toggle-button {
    width: 100%;
}
 
.left-panel {
    height: 100%;
    width: 350px;
    top: 0px;
    left: 0px;

    background-image: url("../../assets/tentacle-left.png");
    background-repeat: no-repeat;
    background-position: left bottom;
}

.header-panel {
    position: fixed;
    height: 60px;
    width: 100%;  
    background-color: #111;
    top: 0px;
}

.bottom-panel {
    height: 250px;
    width: 100%;
    left: 0px;
    bottom: 0px;

    background-image: url("../../assets/tentacle-bottom.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% 100%;
}

.middle-panel {
    height: min(350px, 70vh);
    width: min(500px, 70vw);
    top: 50%;
    left: 50%;
    margin: max(-175px, -35vh) 0 0 max(-250px, -35vw);

    background-image: url("../../assets/tentacle-small.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 100%;
    overflow: auto;
}

.right-panel {
    height: 100%;
    width: 350px;
    top: 0px;
    right: 0px;

    text-align: center;
    background-image: url("../../assets/tentacle-right.png");
    background-repeat: no-repeat;
    background-position: right bottom;
}

.horizontal-panel-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

.vertical-panel-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

.middle-panel-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.hover-pointer {
    cursor: pointer;
}

.panel-text-area {
    background-color: #404040;
    color: white;
    border: 1px solid white;
    resize: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.panel-input-button {
    border-color: white;
    border-width: 1px;
    font-size: 15px;
    padding: 10px;
    width: 100px;
}

.panel-input-button:disabled {
    cursor: not-allowed;
    background-color: #404040;
}

.panel-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
}

.panel-button-container {
    display: flex;
    justify-content: space-around;
    width: 70%;
}

.panel-abilities-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.panel-abilities-header {
    align-self: flex-end;
    font-size: 20px;
}

.panel-abilities-content {
    display: flex;
}

.panel-ability-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
}

.settings-header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.settings-header {
    font-size: 15px;
    background-color: #404040;
    border-radius: 4px;
    border: .5px solid white;
    color: white;
    padding: 3px;
    cursor: pointer;
}

.settings-header:hover {
    background-color: rgba(102, 0, 204, 0.808);
}

.settings-header-active {
    font-size: 15px;
    border-radius: 4px;
    border: .5px solid white;
    color: white;
    padding: 3px;
    background-color: rgba(102, 0, 204, 0.808);
    cursor: pointer;
}

.customize-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.customize-section-header {
    
}

.panel-text-content {
    width: 75%;
    padding-left: 20px;
    white-space: pre-line;
}

.panel-text-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.panel-info-text-container {
    font-size: 15px;
    white-space: pre-line; 
}

.panel-dropdown {
    color: white;
    background-color: black;
    width: 100%;
    border-radius: 3px;
    border-width: 1px;
    border-color: rgb(118, 118, 118);
    border-style: solid;
}