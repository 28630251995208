.App {
  text-align: center;
  background-image: url("assets/theme.png");
  background-size: 100vw 100vh;

  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  justify-content: center;
  top: 0px;
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

.App-title {
  position: absolute;
  left: 0%;
  height: 100%;
  width: 600px;

  padding-left: 20px;
  margin-left: -20px;
  padding-top: 45px;
  margin-top: -45px;
  padding-bottom: 130px;
  margin-bottom: -130px;

  background-image: url("assets/tentacle-heading.png");
  background-repeat: no-repeat;
  background-size: 95%;
  background-position: 0px 0px;
}

.App-title-text {

}

.header-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0px;
  z-index: 2;  
}

.panel-container {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
}

.App-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.medium-text {
  font-size: 14px;
}

.left-text {
  text-align: left;
}
