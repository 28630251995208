.aura {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.aura-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    font-size: 20px;
    z-index: 2;
}

.aura-container {
    display: flex;
    justify-content: center;
}